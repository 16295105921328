/** @format */

import React, { useState, useEffect } from "react";
import moment from "moment";
import { CiSearch } from "react-icons/ci";
import { RiDownloadCloud2Line } from "react-icons/ri";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import Edit from "./emp_edit";
import ExportFieldsPopup from "./export_field_popup";
import usePermissions from "../../../permissions/permission.jsx";
import { IoCloseSharp } from "react-icons/io5";

// Popup component for "View More"
const TextModal = ({ text, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
        <h2 className="text-[20px] text-purple-800 font-bold flex justify-center mb-4">
          Complete Details
        </h2>
        <p className="text-lg font-bold ">{text}</p>
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const Emp_details = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExportPopupOpen, setIsExportPopupOpen] = useState(false); // State for export popup
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [viewMoreText, setViewMoreText] = useState(null); // State for storing full text


  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [employeeData, setEmployeeData] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("All");
  const [statusFilter, setStatusFilter] = useState("All"); // State for active/inactive filter
  const [currentPage, setCurrentPage] = useState(1);
  const [searchName, setSearchName] = useState(""); // Separate state for name search
  const [searchID, setSearchID] = useState(""); // Separate state for ID search
  const rowsPerPage = 10;
  const { hasPermission } = usePermissions();
  const [isUpcomingRetirementsOpen, setIsUpcomingRetirementsOpen] = useState(false);
  const [retirementData, setRetirementData] = useState([]); // State for retirement data

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const API_URL = process.env.REACT_APP_FRONTEND_URL;

  useEffect(() => {
    fetch(`${API_URL}/v1/hris/employees/getemployeebasicdetails`)
      .then((response) => response.json())
      .then((data) => {
        setEmployeeData(data);
        const uniqueDepartments = [
          ...new Set(data.map((employee) => employee.department_name)),
        ];
        setDepartments(uniqueDepartments);
      })
      .catch((error) => console.error("Error fetching employee data:", error));
  }, []);

  const handleEditClick = (employee) => {
    setSelectedEmployee(employee);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedEmployee(null);
  };

  const handleSearchNameChange = (e) => {
    setSearchName(e.target.value);
  };

  const handleSearchIDChange = (e) => {
    setSearchID(e.target.value);
  };

  const filterByEmployeeID = (data, id) => {
    return data.filter((employee) =>
      employee.employee_no.toLowerCase().includes(id.toLowerCase())
    );
  };

  const filterByEmployeeName = (data, name) => {
    return data.filter((employee) =>
      employee.employee_fullname.toLowerCase().includes(name.toLowerCase())
    );
  };

  const filterByStatus = (data, status) => {
    if (status === "All") {
      return data;
    }
    return data.filter(
      (employee) =>
        employee.employee_active_status &&
        employee.employee_active_status.toLowerCase() === status.toLowerCase()
    );
  };

  const filterData = () => {
    let filteredData = employeeData;

    if (searchID) {
      filteredData = filterByEmployeeID(filteredData, searchID);
    }

    if (searchName) {
      filteredData = filterByEmployeeName(filteredData, searchName);
    }

    if (statusFilter !== "All") {
      filteredData = filterByStatus(filteredData, statusFilter);
    }

    if (selectedDepartment !== "All") {
      filteredData = filteredData.filter(
        (employee) => employee.department_name === selectedDepartment
      );
    }

    return filteredData;
  };

  const openExportPopup = () => {
    setIsExportPopupOpen(true);
  };

  const handleExport = (selectedFields) => {
    const searchData = filterData(); // Apply search filters on the entire employeeData array

    const csvData = searchData.map((employee) => {
      const filteredEmployee = {};
      Object.keys(selectedFields).forEach((key) => {
        if (selectedFields[key]) {
          switch (key) {
            case "employee_no":
              filteredEmployee["Employee ID"] = employee.employee_no;
              break;
            case "employee_fullname":
              filteredEmployee["Full Name"] = employee.employee_fullname;
              break;
            case "department_name":
              filteredEmployee["Department"] = employee.department_name;
              break;
            case "employee_calling_name":
              filteredEmployee["Calling Name"] = employee.employee_calling_name;
              break;
            case "employee_nic":
              filteredEmployee["NIC"] = employee.employee_nic;
              break;
            case "employee_contact_no":
              filteredEmployee["Contact Number"] = employee.employee_contact_no;
              break;
            case "employee_email":
              filteredEmployee["Email Address"] = employee.employee_email;
              break;
            case "employee_active_status":
              filteredEmployee["Status"] = employee.employee_active_status;
              break;
            case "designation_name":
              filteredEmployee["Designation"] = employee.designation_name;
              break;
            default:
              filteredEmployee[key] = employee[key];
              break;
          }
        }
      });
      return filteredEmployee;
    });

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "employee_data.csv");
  };

  const searchData = filterData();

  const totalPages = Math.ceil(searchData.length / rowsPerPage);

  const currentData = searchData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    const startPage = Math.max(
      1,
      currentPage - Math.floor(maxPageNumbersToShow / 2)
    );
    const endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`mx-1 px-3 py-1 rounded-lg ${currentPage === i
            ? "bg-blue-500 text-white"
            : "bg-gray-200 text-gray-700"
            }`}
        >
          {i}
        </button>
      );
    }

    if (startPage > 1) {
      pageNumbers.unshift(
        <button
          key="prev"
          onClick={() => handlePageChange(startPage - 1)}
          className="mx-1 px-3 py-1 rounded-lg bg-gray-200 text-gray-700"
        >
          &laquo; See Less
        </button>
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <button
          key="next"
          onClick={() => handlePageChange(endPage + 1)}
          className="mx-1 px-3 py-1 rounded-lg bg-gray-200 text-gray-700"
        >
          See More &raquo;
        </button>
      );
    }

    return pageNumbers;
  };

  const truncateText = (text) => {
    if (!text) return ""; // If text is null or undefined, return an empty string
    return text.length > 12 ? text.substring(0, 12) + "..." : text;
  };

  const handleViewMoreClick = (text) => {
    setViewMoreText(text);
  };

  return (
    <div className="mx-10 fixed">
      <div className="mr-[20%]">
        <div className="flex justify-between items-center mt-6 ">
          <div>
            <p className="text-[30px] font-semibold">View Employee Details</p>
          </div>
          <div>
            <div>
              <div className="bg-primary_purple p-2 rounded-xl">
                <div className="items-center flex justify-between">
                  <p className="mr-6 text-white font-bold">Upcoming Retirements</p>
                  <button
                    className="bg-[#3C0660] text-white rounded-lg p-2 font-bold"
                    onClick={() => {
                      setIsUpcomingRetirementsOpen(true);
                      // Fetch retirement data here if necessary
                    }}
                  >
                    View
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="flex justify-between items-center">
            <div className="flex gap-4 items-center">
              <div className="relative">
                <input
                  className="border border-black rounded-xl p-3 pl-10 w-[200px]"
                  placeholder="Search by ID"
                  value={searchID}
                  onChange={handleSearchIDChange}
                />
                <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
              </div>

              <div className="relative">
                <input
                  className="border border-black rounded-xl p-3 pl-10 w-[200px]"
                  placeholder="Search by Full Name"
                  value={searchName}
                  onChange={handleSearchNameChange}
                />
                <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
              </div>
            </div>
            <div>
              <div className="relative flex items-center gap-3 ">
                <select
                  className="p-3 shadow-custom rounded-[12px] "
                  onChange={(e) => setSelectedDepartment(e.target.value)}
                >
                  <option value="All">All Departments</option>
                  {departments.map((dept, index) => (
                    <option key={index} value={dept}>
                      {dept}
                    </option>
                  ))}
                </select>
                <select
                  className="p-3 shadow-custom rounded-[12px]"
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <option value="All">Active / Inactive</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
                {hasPermission(3021) && (
                  <button
                    className="p-3 text-black px-4 py-2 rounded-md shadow-custom"
                    onClick={openExportPopup}
                  >
                    <div className="flex gap-3 items-center">
                      <div>Export CSV</div>
                      <RiDownloadCloud2Line />
                    </div>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mr-[5%] mt-2">
        <div className="overflow-auto">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 table-fixed">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-4 py-2 text-left text-md font-semibold text-gray-500  uppercase tracking-wider">
                    ID
                  </th>
                  <th className="px-4 py-2 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                    Full Name
                  </th>
                  <th className="px-4 py-2 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                    Department
                  </th>

                  <th className="px-4 py-2 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                    Calling Name
                  </th>
                  <th className="px-4 py-2 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                    NIC
                  </th>

                  <th className="px-4 py-2 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                    Contact Number
                  </th>

                  <th className="px-4 py-2 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                    Email Address
                  </th>

                  <th className="px-4 py-2 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-4 py-2 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                    Designation
                  </th>
                  <th className="px-4 py-2 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {currentData.map((employee, index) => (
                  <tr
                    key={employee.id}
                    className={index % 2 === 0 ? "bg-purple-100" : ""}
                  >
                    <td className="px-4 py-2 whitespace-nowrap text-md font-medium text-gray-900">
                      {truncateText(employee.employee_no)}
                      {employee.employee_no &&
                        employee.employee_no.length > 12 && (
                          <button
                            className="ml-2 text-blue-600 hover:text-blue-800"
                            onClick={() =>
                              handleViewMoreClick(employee.employee_no)
                            }
                          >
                            View More
                          </button>
                        )}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-md text-gray-500">
                      {truncateText(employee.employee_fullname)}
                      {employee.employee_fullname &&
                        employee.employee_fullname.length > 12 && (
                          <button
                            className="ml-2 text-blue-600 hover:text-blue-800"
                            onClick={() =>
                              handleViewMoreClick(employee.employee_fullname)
                            }
                          >
                            View More
                          </button>
                        )}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-md text-gray-500">
                      {truncateText(employee.department_name)}
                      {employee.department_name &&
                        employee.department_name.length > 12 && (
                          <button
                            className="ml-2 text-blue-600 hover:text-blue-800"
                            onClick={() =>
                              handleViewMoreClick(employee.department_name)
                            }
                          >
                            View More
                          </button>
                        )}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-md text-gray-500">
                      {truncateText(employee.employee_calling_name)}
                      {employee.employee_calling_name &&
                        employee.employee_calling_name.length > 12 && (
                          <button
                            className="ml-2 text-blue-600 hover:text-blue-800"
                            onClick={() =>
                              handleViewMoreClick(
                                employee.employee_calling_name
                              )
                            }
                          >
                            View More
                          </button>
                        )}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-md text-gray-500">
                      {truncateText(employee.employee_nic)}
                      {employee.employee_nic &&
                        employee.employee_nic.length > 12 && (
                          <button
                            className="ml-2 text-blue-600 hover:text-blue-800"
                            onClick={() =>
                              handleViewMoreClick(employee.employee_nic)
                            }
                          >
                            View More
                          </button>
                        )}
                    </td>

                    <td className="px-4 py-2 whitespace-nowrap text-md text-gray-500">
                      {truncateText(employee.employee_contact_no)}
                      {employee.employee_contact_no &&
                        employee.employee_contact_no.length > 12 && (
                          <button
                            className="ml-2 text-blue-600 hover:text-blue-800"
                            onClick={() =>
                              handleViewMoreClick(employee.employee_contact_no)
                            }
                          >
                            View More
                          </button>
                        )}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-md text-gray-500">
                      {truncateText(employee.employee_email)}
                      {employee.employee_email &&
                        employee.employee_email.length > 12 && (
                          <button
                            className="ml-2 text-blue-600 hover:text-blue-800"
                            onClick={() =>
                              handleViewMoreClick(employee.employee_email)
                            }
                          >
                            View More
                          </button>
                        )}
                    </td>

                    <td className="px-4 py-2 whitespace-nowrap text-md text-gray-500">
                      {truncateText(employee.employee_active_status)}
                      {employee.employee_active_status &&
                        employee.employee_active_status.length > 12 && (
                          <button
                            className="ml-2 text-blue-600 hover:text-blue-800"
                            onClick={() =>
                              handleViewMoreClick(
                                employee.employee_active_status
                              )
                            }
                          >
                            View More
                          </button>
                        )}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-md text-gray-500">
                      {truncateText(employee.designation_name)}
                      {employee.designation_name &&
                        employee.designation_name.length > 12 && (
                          <button
                            className="ml-2 text-blue-600 hover:text-blue-800"
                            onClick={() =>
                              handleViewMoreClick(employee.designation_name)
                            }
                          >
                            View More
                          </button>
                        )}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-md font-medium">
                      {hasPermission(3020) && (
                        <button
                          className="text-white bg-purple-600 p-2 rounded-md"
                          onClick={() => handleEditClick(employee)}
                        >
                          View and Edit
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>{" "}
          <div className="mt-4 flex justify-end">{renderPageNumbers()}</div>
        </div>
        {isModalOpen && selectedEmployee && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-200 bg-opacity-80 z-50">
            <div className="bg-white rounded-lg p-8 h-[80%] overflow-y-auto">
              <Edit onClose={closeModal} employee={selectedEmployee} />
            </div>
          </div>
        )}

        {isExportPopupOpen && (
          <ExportFieldsPopup
            onClose={() => setIsExportPopupOpen(false)}
            onExport={handleExport}
          />
        )}

        {viewMoreText && (
          <TextModal
            text={viewMoreText}
            onClose={() => setViewMoreText(null)}
          />
        )}
      </div>

      {isUpcomingRetirementsOpen && (
        <div className="fixed top-0 left-0 h-full w-full flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg w-[80%] h-[80%] relative">
            <button
              className="absolute top-2 right-2 bg-white text-[#797C80] rounded-full p-1"
              onClick={() => setIsUpcomingRetirementsOpen(false)}
            >
              <div className="bg-[#EFA5A5] rounded-full p-2">
                <IoCloseSharp />
              </div>
            </button>
            <p className="text-center font-sans font-bold text-[24px] text-[#797C80] mb-4">
              Upcoming Retirements
            </p>
            <div className="h-[90%] overflow-y-auto scrollbar-thin scrollbar-thumb-[#c0c0c0]">
              <table className="w-full border-none">
                <thead>
                  <tr className="text-left">
                    <th className="bg-gray-300 rounded-l-xl text-[16px] font-normal font-sans py-2 w-[10%] pl-4">
                      Emp ID
                    </th>
                    <th className="bg-gray-300 text-[16px] font-normal font-sans py-2 w-[35%]">
                      Employee Calling Name
                    </th>
                    <th className="bg-gray-300 text-[16px] font-normal font-sans py-2 w-[25%]">
                      Designation
                    </th>
                    <th className="bg-gray-300 text-[16px]  font-normal font-sans py-2 w-[30%]">
                      On-Boarded Date
                    </th>
                    <th className="bg-gray-300 text-[16px] rounded-r-xl font-normal font-sans py-2 w-[30%] pr-4">
                      Retirement Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {retirementData.map((data, index) => (
                    <tr key={index} className={`text-left ${index % 2 === 0 ? "bg-gray-100" : ""}`}>
                      <td className="py-2 px-4 text-[#1F2937] w-[5%]"></td>
                      <td className="py-2 px-4 w-[25%]">
                      </td>
                      <td className="py-2 px-4 text-[#1F2937] w-[25%]"></td>
                      <td className="py-2 px-4 w-[30%]">
                      </td>
                      <td className="py-2 px-4 w-[30%]">
                        <div className=""></div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default Emp_details;
