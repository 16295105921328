import React, { useState, useEffect } from "react";
import moment from "moment";
import LeaveDataTable from "./emp_leave_table";
import AddLeaveQuotaForm from "./add_leave_quota";
import usePermissions from "../../../permissions/permission";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./leave_reset_calendar.css";

const EmpLeaveManagement = () => {
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [leaveData, setLeaveData] = useState([]); // State to hold leave data
  const [showDatePicker, setShowDatePicker] = useState(false); // Toggle date picker popup
  const [selectedDate, setSelectedDate] = useState(new Date()); // For calendar selection
  const [showConfirmDialog, setShowConfirmDialog] = useState(false); // Toggle confirm dialog
  const [successMessage, setSuccessMessage] = useState("");
  const { hasPermission } = usePermissions();
  const API_URL = process.env.REACT_APP_FRONTEND_URL;
  const currentDate = moment().format("MMMM Do YYYY");

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleResetLeaveCount = () => {
    setShowDatePicker(true); // Show date picker popup when reset button is clicked
  };

  const confirmResetLeaveCount = async () => {
    try {
      const response = await fetch(
        `${API_URL}/v1/hris/leave/resetleavecounts`,
        {
          method: "PUT",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to reset leave counts");
      }

      const data = await response.json();
      setSuccessMessage(data.message);

      // Update the leaveData state to reset current_leave_counts
      const updatedLeaveData = leaveData.map((employee) => ({
        ...employee,
        current_leave_counts: {
          shortleavecount: 0,
          halfdaycount: 0,
          anualleavecount: 0,
          casualleavecount: 0,
          medicalleavecount: 0,
        },
      }));

      setLeaveData(updatedLeaveData);
      setShowConfirmDialog(false);
    } catch (error) {
      console.error("Error resetting leave counts:", error);
      setSuccessMessage("Error resetting leave counts");
    }
  };

  // Fetch leave data when the component mounts
  useEffect(() => {
    fetchLeaveData();
  }, []);

  const fetchLeaveData = async () => {
    try {
      const response = await fetch(
        `${API_URL}/v1/hris/leave/GetLeaveCountstoallemployee`
      );
      const data = await response.json();
      setLeaveData(data);
    } catch (error) {
      console.error("Error fetching leave data:", error);
    }
  };

  const handleFormSubmitSuccess = () => {
    setIsFormOpen(false); // Close the form popup
    fetchLeaveData(); // Refresh the table data
  };

  const handleContinue = () => {
    // Show confirmation popup after selecting the date and clicking Continue
    setShowDatePicker(false); // Close date picker
    setShowConfirmDialog(true); // Show confirmation dialog
  };

  return (
    <div className="mx-10 mt-5">
      <div className="overflow-x-hidden mr-[2%]">
        <div className="flex justify-between items-center mt-6">
          <div>
            <p className="text-[30px] font-semibold">
              Employee Leave Management
            </p>
            <p className="text-[15px] font-semibold text-primary_purple">
              Employee Leave Management
            </p>
          </div>
        </div>
        <div className="mt-5">
          <div>
            {hasPermission(3300) && (
              <button
                className="bg-primary_purple text-white p-3 rounded-[12px] font-semibold"
                onClick={handleResetLeaveCount}
              >
                Reset Leave Count
              </button>
            )}
          </div>
        </div>

        <div className="mt-5">
          <div>
            {hasPermission(3310) && (
              <button
                className="bg-primary_purple text-white p-3 rounded-[12px] font-semibold"
                onClick={toggleForm}
              >
                Add Leave Quota
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="overflow-x-auto mr-[5%]">
        <LeaveDataTable
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          leaveData={leaveData}
        />
      </div>

      {isFormOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-3/4 max-w-3xl">
            <AddLeaveQuotaForm onSuccess={handleFormSubmitSuccess} />
            <button
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
              onClick={toggleForm}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Reset Leave Count Date Picker Popup */}
      {showDatePicker && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="flex justify-center ">
              <p className="text-lg font-semibold mb-4 text-[25px]">
                Please Select a Date to Reset the Count
              </p>
            </div>
            <div className="flex flex-col items-center custom-datepicker">
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                inline
                className="text-center"
              />
            </div>
            <div className="mt-4 flex justify-end">
              <button
                className="bg-primary_purple text-white px-4 py-2 rounded mr-2"
                onClick={handleContinue}
              >
                Continue
              </button>
              <button
                className="bg-gray-300 text-black px-4 py-2 rounded"
                onClick={() => setShowDatePicker(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Confirmation Dialog After Date Selection */}
      {showConfirmDialog && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-10 rounded-lg shadow-lg">
            <p className="text-[22px]">
              <p>
                Are you sure you want to
              </p>

              <p>reset all leave counts on{" "}</p>
              <p className="font-bold text-primary_purple flex justify-center">{moment(selectedDate).format("DD/MM/YYYY")}?</p>
            </p>
            <div className="mt-4 flex justify-center gap-4">
              <button
                className="bg-white rounded-[30px] border border-primary_purple p-2 w-[80px]"
                onClick={() => setShowConfirmDialog(false)}
              >
                No
              </button>
              <button
                className="bg-primary_purple text-white p-2 w-[80px] rounded-[30px]"
                onClick={confirmResetLeaveCount}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      {successMessage && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p>{successMessage}</p>
            <button
              className="mt-4 bg-primary_purple text-white px-4 py-2 rounded"
              onClick={() => setSuccessMessage("")}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmpLeaveManagement;
