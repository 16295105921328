import React, { useState, useEffect } from "react";
import Navbar from "../../navbar/navbar.jsx";
import moment from "moment";
import Emp_Chart from "./emp_Chart.jsx";
import Leave_Details from "./leave_details.jsx";
import Emp_barchart from "./emp_barchart.jsx";
import usePermissions from "../../../permissions/permission.jsx";
import Users from "../../../../assets/users.png";
import Work from "../../../../assets/work.png";
import Absense from "../../../../assets/absence.png";
import Late from "../../../../assets/late.png";
import Leave from "../../../../assets/leave.png";
import "animate.css";

const Dashboard = () => {
  const [data, setData] = useState({
    totalWorkforce: 0,
    presentWorkforce: 0,
    absentWorkforce: 0,
    lateArrivals: 0,
    inLeave: 0,
  });

  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const { hasPermission } = usePermissions();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const API_URL = process.env.REACT_APP_FRONTEND_URL;

  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const today = moment().format("YYYY-MM-DD");

        // Fetch attendance stats
        const response = await fetch(
          `${API_URL}/v1/hris/employees/getAttendanceStats`
        );
        const result = await response.json();

        if (result.success) {
          const { totalWorkforce, presentWorkforce, inLeave } = result.data;

          // Update the state with fetched data
          setData((prevData) => ({
            ...prevData,
            totalWorkforce,
            presentWorkforce,
            inLeave,
          }));
        } else {
          console.error(
            "Error fetching attendance stats:",
            result.error || result
          );
        }

        // Fetch absent workforce count with today's date as startDate and endDate
        const absentResponse = await fetch(
          `${API_URL}/v1/hris/attendence/getNotAttendCount?startDate=${today}&endDate=${today}`
        );
        const absentResult = await absentResponse.json();

        if (absentResult.not_attended_count !== undefined) {
          setData((prevData) => ({
            ...prevData,
            absentWorkforce: absentResult.not_attended_count,
          }));
        } else {
          console.error(
            "Error fetching absent workforce count:",
            absentResult.error || absentResult
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [API_URL]);

  useEffect(() => {
    const fetchLateInCount = async () => {
      try {
        const today = moment().format("YYYY-MM-DD");

        // Fetch late in count with today's date
        const response = await fetch(
          `${API_URL}/v1/hris/attendence/getLateInCount?date=${today}`
        );
        const result = await response.json();

        if (result.success) {
          const { late_in_count } = result;

          // Update the state with fetched late in count
          setData((prevData) => ({
            ...prevData,
            lateArrivals: late_in_count,
          }));
        } else {
          console.error(
            "Error fetching late in count:",
            result.error || result
          );
        }
      } catch (error) {
        console.error("Error fetching late in count:", error);
      }
    };
    fetchLateInCount();
  }, [API_URL]);

  return (
    <div className="mx-10 mt-5">
      {/* top layer */}
     

      {/* second layer */}
      <div className="flex justify-between items-center mt-6">
        <div>
          <p className="text-[30px] font-semibold">
            Dashboard - Employee Information Management
          </p>
        </div>
        <div className="flex gap-6 items-center">
          <div>
            <div className="text-[#3D0B5E] text-[20px] font-bold">
              {currentDate}
            </div>
          </div>
          <div className="text-[20px] font-bold">{currentTime}</div>
        </div>
      </div>

      {/* card layer */}
      <div className="mt-10">
        <div className="grid grid-cols-5 grid-flow-rows gap-5">
          {/* card 1 */}
          {hasPermission(3000) && (
            <div className=" p-3 rounded-xl border border-gray-100  bg-white shadow-md">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <img src={Users} className="w-[70px]" />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <p className="mt-5 font-semibold">Total Workforce</p>
                <p className="text-[50px] font-bold">{data.totalWorkforce}</p>
              </div>
            </div>
          )}
          {/* card 2 */}
          {hasPermission(3001) && (
            <div className="p-3 rounded-xl border border-gray-100  bg-white shadow-md">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <img src={Work} className="w-[70px]" />
                </div>
              </div>
              <div className="flex justify-between items-center">
                <p className="mt-5 font-semibold">Present Workforce</p>
                <p className="text-[50px] font-bold">{data.presentWorkforce}</p>
              </div>
            </div>
          )}
          {/* card 3 */}
          {hasPermission(3002) && (
            <div className="p-3 rounded-xl border border-gray-100  bg-white shadow-md">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <img src={Absense} className="w-[70px]" />
                </div>
              </div>
              <div className="flex justify-between items-center">
                <p className="mt-5 font-semibold">Absent Workforce</p>
                <p className="text-[50px] font-bold">{data.absentWorkforce}</p>
              </div>
            </div>
          )}
          {/* card 4 */}
          {hasPermission(3003) && (
            <div className="p-3 rounded-xl border border-gray-100  bg-white shadow-md">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <img src={Late} className="w-[70px]" />
                </div>
              </div>
              <div className="flex justify-between items-center">
                <p className="mt-5 font-semibold">Late Arrivals</p>
                <p className="text-[50px] font-bold">{data.lateArrivals}</p>
              </div>
            </div>
          )}
          {/* card 5 */}
          {hasPermission(3004) && (
            <div className="p-3 border border-gray-100 rounded-xl bg-white shadow-md">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <img src={Leave} className="w-[70px]" />
                </div>
              </div>
              <div className="flex justify-between items-center">
                <p className="mt-5 font-semibold">In Leave</p>
                <p className="text-[50px] font-bold">{data.inLeave}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* lower layer */}
      <div className="flex gap-3 items-center w-full mt-8">
        {/* section1 */}
        {hasPermission(3005) && (
          <div
            className="flex-auto animate__fadeInLeft animate__animated -z-40"
            style={{ animationDelay: "1s" }}
          >
            <Leave_Details data={data} />
          </div>
        )}
        {/* section2 */}
        {hasPermission(3006) && (
          <div
            className=" w-[400px] h-[450px] mr-3 animate__slideInUp animate__animated"
            style={{ animationDelay: "1s" }}
          >
            <div className="shadow-xl rounded-xl">
              <div className="mt-[-30px]">
                <Emp_Chart data={data} />
              </div>
            </div>
          </div>
        )}
        {hasPermission(3007) && (
          <div
            className="flex-auto mt-10 shadow-xl p-5 pl-8 rounded-xl animate__fadeInRight animate__animated"
            style={{ animationDelay: "1s" }}
          >
            <Emp_barchart data={data} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
