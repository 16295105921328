/** @format */

import React, { useState, useEffect } from "react";
import Navbar from "../navbar/navbar";
import moment from "moment";
import { CiSearch } from "react-icons/ci";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import usePermissions from "../../permissions/permission";

const Absence_Report = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(moment().toDate()); // Set current date as default
  const [endDate, setEndDate] = useState(moment().toDate()); // Set current date as default
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const rowsPerPage = 10;
  const { hasPermission } = usePermissions();
  const API_URL = process.env.REACT_APP_FRONTEND_URL;

  const handleFetchData = async () => {
    if (startDate && endDate) {
      setIsLoading(true); // Start loading
      const formattedStartDate = moment(startDate).format(
        "YYYY-MM-DD 00:00:00"
      );
      const formattedEndDate = moment(endDate).format("YYYY-MM-DD 23:59:59");

      try {
        const response = await fetch(
          `${API_URL}/v1/hris/attendence/getNotAttend?startDate=${formattedStartDate}&endDate=${formattedEndDate}`
        );
        const result = await response.json();

        if (Array.isArray(result)) {
          setData(result); // If the result itself is the data array
        } else if (result.success && Array.isArray(result.data)) {
          setData(result.data); // If the data is within the result.data field
        } else {
          setData([]);
          console.error(
            "Failed to fetch data: ",
            result.message || "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setData([]);
      } finally {
        setIsLoading(false); // Stop loading
      }
    } else {
      alert("Please select both start and end dates.");
    }
  };

  useEffect(() => {
    // Fetch data for the current date on component mount
    handleFetchData();
  }, []);

  const exportToCSV = () => {
    const csvData = filteredData.map((row) => ({
      Date: moment(row.date).format("YYYY-MM-DD"), // Format the date
      EmployeeNumber: row.employee_no, // Employee Number
      EmployeeName: row.employee_fullname, // Employee Name
      Department: row.department, // Department
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "absence_report_filtered.csv");
  };

  const filteredData = data.filter((row) => {
    const matchesSearch = row.employee_no
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesDepartment =
      selectedDepartment === "" || row.department === selectedDepartment;
    return matchesSearch && matchesDepartment;
  });

  const departments = [...new Set(data.map((row) => row.department))];

  const paginatedData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const maxVisiblePages = 5;
  const startPage = Math.max(
    Math.min(
      currentPage - Math.floor(maxVisiblePages / 2),
      totalPages - maxVisiblePages + 1
    ),
    1
  );
  const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="mx-10 mt-5">

      <div className="flex justify-between mt-6">
        <div>
          <p className="text-[30px] font-semibold">Absence Report</p>
        </div>

      </div>

      <div>
        <div className="flex justify-between items-center">
          <div className="flex gap-4 items-center mt-5">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                placeholder="Search by Employee Number"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
            </div>
            <div>
              <select
                className=" text-black px-4 py-2 rounded-md shadow-custom"
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e.target.value)}
              >
                <option value="">All Departments</option>
                {departments.map((department, index) => (
                  <option key={index} value={department}>
                    {department}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center space-x-2 bg-white rounded-[20px] px-4 py-2 shadow-sm border border-black">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy-MM-dd"
                className="text-sm text-gray-600 focus:outline-none"
                placeholderText="Start Date"
              />
              <span className="text-gray-400">-</span>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="yyyy-MM-dd"
                className="text-sm text-gray-600 focus:outline-none"
                placeholderText="End Date"
              />
            </div>
            <button
              className="p-3  text-black px-4 py-2 rounded-md shadow-custom"
              onClick={handleFetchData}
            >
              Get Data
            </button>
          </div>

          <div className="relative flex items-center gap-3">
            {hasPermission(3520) && (
              <button
                className="p-3  text-black px-4 py-2 rounded-md shadow-custom"
                onClick={exportToCSV}
              >
                <div className="flex gap-3 items-center">
                  <div>Export CSV</div>
                </div>
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="overflow-x-auto mt-5 w-full">
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <p className="text-xl font-bold text-gray-600">
              Loading please wait...
            </p>
          </div>
        ) : (
          <>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider w-1/6">
                    Date
                  </th>
                  <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider w-1/6">
                    Employee Number
                  </th>
                  <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider w-1/6">
                    Employee Name
                  </th>
                  <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider w-1/6">
                    Department
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {paginatedData.length > 0 ? (
                  paginatedData.map((row, index) => {
                    const globalIndex = (currentPage - 1) * rowsPerPage + index;
                    return (
                      <tr
                        key={index}
                        className={
                          globalIndex % 2 === 0 ? "bg-purple-100" : "bg-white"
                        }
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 w-1/6">
                          {moment(row.date).format("DD/MM/YY")}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 w-1/6">
                          {row.employee_no}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 w-1/6">
                          {row.employee_fullname}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 w-1/6">
                          {row.department}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan="6"
                      className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500"
                    >
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Pagination */}
            <div className="flex justify-between items-center py-3">
              <div>
                Showing{" "}
                {paginatedData.length > 0
                  ? (currentPage - 1) * rowsPerPage + 1
                  : 0}{" "}
                to{" "}
                {currentPage * rowsPerPage > filteredData.length
                  ? filteredData.length
                  : currentPage * rowsPerPage}{" "}
                of {filteredData.length} records
              </div>
              <div className="flex space-x-2">
                {/* Previous Button */}
                {startPage > 1 && (
                  <>
                    <button
                      onClick={() => handlePageChange(1)}
                      className="px-3 py-1 border rounded-md"
                    >
                      1
                    </button>
                    {startPage > 2 && <span className="px-3 py-1">...</span>}
                  </>
                )}

                {/* Page Numbers */}
                {Array.from(
                  { length: endPage - startPage + 1 },
                  (_, i) => i + startPage
                ).map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={`px-3 py-1 border rounded-md ${currentPage === page ? "bg-gray-300" : "bg-white"
                      }`}
                  >
                    {page}
                  </button>
                ))}

                {/* Next Button */}
                {endPage < totalPages && (
                  <>
                    {endPage < totalPages - 1 && (
                      <span className="px-3 py-1">...</span>
                    )}
                    <button
                      onClick={() => handlePageChange(totalPages)}
                      className="px-3 py-1 border rounded-md"
                    >
                      {totalPages}
                    </button>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Absence_Report;
