import React, { useState } from "react";
import { FaRegBell } from "react-icons/fa";
import Cookies from "js-cookie";
import Hi from "../../../assets/hi.png";

const Navbar = () => {
  const [showNotifications, setShowNotifications] = useState(false); // State to show/hide notifications
  const user = Cookies.get("employee_calling_name");
  const notifications = [
    {
      id: 1,
      message: "05 New Leave Requests",
      time: "3 MIN AGO",
      description: "Please find that you have some new Leave Requests",
    },
  ]; // Example notification data

  const getGreeting = () => {
    const now = new Date();
    const hour = now.getHours();

    if (hour < 12) {
      return "Good Morning 🌞";
    } else if (hour < 17) {
      return "Good Afternoon 🌞";
    } else {
      return "Good Evening 🌑";
    }
  };

  // Toggle the notification dropdown
  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <div className="flex items-center">
            <p className="text-[20px] font-bold">
              {getGreeting()}, {user}
            </p>
            <img src={Hi} alt="hi" className="w-10 h-8 mb-5" />
          </div>
        </div>
        <div>
          <div className="flex gap-4 items-center relative">
            {/* Bell Icon with notification count */}
            <div className="relative" onClick={toggleNotifications}>
              <FaRegBell className="w-[30px] h-[30px] cursor-pointer" />
              {notifications.length > 0 && (
                <span className="absolute top-3 left-4 bg-[#8764A0] text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                  {notifications.length}
                </span>
              )}
            </div>
            <div>
              <p className="text-[20px] font-semibold">{user}</p>
            </div>

            {/* Notification Dropdown */}
            {showNotifications && (
              <div className="absolute top-[40px] right-0 w-[450px] bg-white rounded-lg z-10 shadow-xl">
                {notifications.map((notification) => (
                  <div key={notification.id} className="p-4 border-b">
                    <div className="flex items-center justify-between ">
                      <div className="mr-3">
                        <div className="bg-[#8764A0] rounded-full h-[120px] w-2"></div>
                      </div>
                      <div>

                        <div className="flex justify-between items-center">
                          <div className="flex items-center cursor-pointer">
                            <FaRegBell className="mr-2 text-gray-500" />
                            <p className="text-sm text-gray-600">
                              {notification.time}
                            </p>
                          </div>
                          <button
                            onClick={() => setShowNotifications(false)}
                            className="text-gray-500 hover:text-gray-700"
                          >
                            X
                          </button>
                        </div>
                        <div className="mt-2">
                          <p className="font-bold">{notification.message}</p>
                          <p className="text-sm text-gray-500">
                            {notification.description}
                          </p>
                          <a
                            href="#"
                            className="text-[#8764A0] text-sm mt-2 inline-block font-semibold"
                          >
                            Click here to take an Action
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
