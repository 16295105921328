import React, { useState, useEffect } from "react";
import { CiSearch } from "react-icons/ci";
import { RiDownloadCloud2Line } from "react-icons/ri";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
import usePermissions from "../../permissions/permission";
import Logo_HRIS from "../../../assets/LOGO HRIS 6.png";

const CheckinCheckoutReportTable = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(""); // State to track selected status
  const [startDate, setStartDate] = useState(moment().toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const { hasPermission } = usePermissions();
  const API_URL = process.env.REACT_APP_FRONTEND_URL;
  const rowsPerPage = 12;

  const getStatusStyle = (actionType) => {
    switch (actionType) {
      case "late in":
        return "bg-[#FFE9D0] p-1";
      case "early out":
        return "bg-[#FFFED3] p-1";
      case "late in / early out":
        return "bg-[#BBE9FF] p-1";
      case "missing out":
        return "bg-[#FABC3F] text-white p-1";
      case "missing in":
        return "bg-[#E85C0D] text-white p-1";
      case "normal check-in":
        return "bg-[#B1AFFF] text-white p-1";
      case "normal":
      case "normal in / early out":
        return "bg-[#A7FFC5] p-1"; // Add color for normal statuses
      default:
        return "bg-gray-100 text-gray-800"; // Default class for other types
    }
  };

  const handleFetchData = async () => {
    if (startDate && endDate) {
      setIsLoading(true); // Start loading
      const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
      const formattedEndDate = moment(endDate).format("YYYY-MM-DD");

      try {
        const response = await fetch(
          `${API_URL}/v1/hris/attendence/getAttendanceHistroy?startDate=${formattedStartDate}&endDate=${formattedEndDate}`
        );
        const result = await response.json();
        if (result.success) {
          // Map the API data to the expected format
          const mappedData = result.data.map((item) => ({
            employee_number: item.employee_number, // Ensure consistency with API response
            employee_name: item.employee_name || "- -", // Use placeholder if necessary
            check_in_time: item.check_in_time,
            check_out_time: item.check_out_time || "- -", // Use placeholder if necessary
            department: item.department || "- -", // Use placeholder if necessary
            status: item.status || "- -", // Use placeholder if necessary
            address: item.address || "- -", // Use placeholder if necessary
          }));
          setData(mappedData);
        } else {
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setData([]);
      } finally {
        setIsLoading(false); // Stop loading
      }
    } else {
      alert("Please select both start and end dates.");
    }
  };

  useEffect(() => {
    // Fetch data for the current date on component mount
    handleFetchData();
  }, []);

  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Attendance Data");

    // Define headers
    worksheet.columns = [
      { header: "Date", key: "Date", width: 30 },
      { header: "Employee Number", key: "EmployeeNumber", width: 30 },
      { header: "Employee Name", key: "EmployeeName", width: 30 },
      { header: "Check-In Time", key: "CheckInTime", width: 30 },
      { header: "Check-Out Time", key: "CheckOutTime", width: 30 },
      { header: "Department", key: "Department", width: 30 },
      { header: "Status", key: "Status", width: 30 },
      { header: "Address", key: "Address", width: 30 },
    ];

    // Add rows
    filteredEmployeeData.forEach((employee) => {
      worksheet.addRow({
        Date: moment(employee.check_in_time).format("DD/MM/YY"), // Updated date format
        EmployeeNumber: employee.employee_number,
        EmployeeName: employee.employee_name,
        CheckInTime: employee.check_in_time,
        CheckOutTime: employee.check_out_time,
        Department: employee.department,
        Status: employee.status,
        Address: employee.address,
      });
    });

    // Apply header styles
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true, color: { argb: "6d709c" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "f3f4f6" },
      };
      cell.alignment = { horizontal: "center" };
    });

    // Apply row styling (alternative row colors)
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber !== 1) {
        const isEvenRow = rowNumber % 2 === 0;
        row.eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: isEvenRow ? "f4e5ff" : "FFFFFFFF" },
          };
          cell.alignment = { horizontal: "center", vertical: "middle" };
        });
        row.height = 30;
      }
    });

    // Generate and save the file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "attendance_data.xlsx");
  };


  const filteredEmployeeData = data.filter((employee) => {
    const matchesSearch = employee.employee_number
      ? employee.employee_number
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
      : false;

    const matchesDepartment =
      selectedDepartment === "" || employee.department === selectedDepartment;

    const matchesStatus =
      selectedStatus === "" || employee.status === selectedStatus;

    // Ensure that the department filter is applied correctly
    return matchesSearch && matchesDepartment && matchesStatus;
  });

  const departments = [...new Set(data.map((employee) => employee.department))];

  const currentData = filteredEmployeeData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const totalPages = Math.ceil(filteredEmployeeData.length / rowsPerPage);

  const maxVisiblePages = 5;
  const startPage = Math.max(
    Math.min(
      currentPage - Math.floor(maxVisiblePages / 2),
      totalPages - maxVisiblePages + 1
    ),
    1
  );
  const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleStatusFilter = (status) => {
    setSelectedStatus(status);
    setCurrentPage(1); // Reset to first page when filtering
  };

  // Loader component with semi-transparent overlay
  const Loader = () => {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-200 bg-opacity-75 z-50">
        <img
          src={Logo_HRIS}
          alt="HRIS Logo"
          className="animate__pulse animate__animated animate__infinite w-40 h-40"
        />
      </div>
    );
  };

  return (
    <div className="p-4 relative">
      {isLoading && <Loader />}
      <div
        className={`${isLoading ? "opacity-50" : "opacity-100"
          } transition-opacity duration-300`}
      >
        <div className="mt-5 mb-5">
          <div className="flex justify-between items-center">
            <div className="flex gap-4 items-center">
              <div className="relative">
                <input
                  className="border border-black rounded-xl p-3 pl-10 w-[200px]"
                  placeholder="Search by Employee Number"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
              </div>
              <div>
                <select
                  className=" text-black px-4 py-2 rounded-md shadow-custom"
                  value={selectedDepartment}
                  onChange={(e) => setSelectedDepartment(e.target.value)}
                >
                  <option value="">All Departments</option>
                  {departments.map((department, index) => (
                    <option key={index} value={department}>
                      {department}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex items-center space-x-2 bg-white rounded-[20px] px-4 py-2 shadow-sm border border-black">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="yyyy-MM-dd"
                  className="text-sm text-gray-600 focus:outline-none"
                  placeholderText="Start Date"
                />
                <span className="text-gray-400">-</span>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  dateFormat="yyyy-MM-dd"
                  className="text-sm text-gray-600 focus:outline-none"
                  placeholderText="End Date"
                />
              </div>
              <button
                className=" text-black px-4 py-2 rounded-md shadow-custom"
                onClick={handleFetchData}
              >
                Get Data
              </button>
            </div>

            <div className="relative flex items-center gap-3 mt-5">
              {hasPermission(3530) && (
                <button
                  className=" text-black px-4 py-2 rounded-md shadow-custom"
                  onClick={exportToExcel}
                >
                  <div className="flex gap-3 items-center">
                    <div>Export Document</div>
                    <RiDownloadCloud2Line />
                  </div>
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="overflow-x-auto w-[100%]">
          <div className="flex justify-center space-x-4 mb-4">
            <button
              className={`px-3 py-1 rounded-md ${selectedStatus === "" ? "bg-blue-500 text-white" : "bg-gray-200"
                }`}
              onClick={() => handleStatusFilter("")}
            >
              All
            </button>
            <button
              className={`px-3 py-1 rounded-md ${selectedStatus === "late in"
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
                }`}
              onClick={() => handleStatusFilter("late in")}
            >
              Late IN
            </button>
            <button
              className={`px-3 py-1 rounded-md ${selectedStatus === "early out"
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
                }`}
              onClick={() => handleStatusFilter("early out")}
            >
              Early OUT
            </button>
            <button
              className={`px-3 py-1 rounded-md ${selectedStatus === "late in / early out"
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
                }`}
              onClick={() => handleStatusFilter("late in / early out")}
            >
              Late IN / Early OUT
            </button>
            <button
              className={`px-3 py-1 rounded-md ${selectedStatus === "normal"
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
                }`}
              onClick={() => handleStatusFilter("normal")}
            >
              Normal
            </button>
            <button
              className={`px-3 py-1 rounded-md ${selectedStatus === "normal check-in"
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
                }`}
              onClick={() => handleStatusFilter("normal check-in")}
            >
              Normal Check-in
            </button>
            <button
              className={`px-3 py-1 rounded-md ${selectedStatus === "normal in / early out"
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
                }`}
              onClick={() => handleStatusFilter("normal in / early out")}
            >
              Normal IN / Early OUT
            </button>
          </div>
          <table className="divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Date
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Employee Number
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Employee Name
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Department
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Check-In Time
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Check-Out Time
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Address
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentData.map((employee, index) => {
                let rowClass = index % 2 === 0 ? "bg-purple-100" : "bg-white";

                return (
                  <tr key={employee.employee_number} className={rowClass}>
                    <td className="px-6 py-4 whitespace-nowrap text-t text-gray-500">
                      {moment(employee.check_in_time).format("DD/MM/YY")}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-t text-gray-900">
                      {employee.employee_number}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-t text-gray-900">
                      {employee.employee_name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-t text-gray-900">
                      {employee.department}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-t text-gray-900">
                      {moment(employee.check_in_time).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-t text-gray-900">
                      {employee.check_out_time
                        ? moment(employee.check_out_time).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )
                        : ""}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-md text-black">
                      <span
                        className={`px-2 inline-flex text-md leading-5 font-semibold rounded-full ${getStatusStyle(
                          employee.status
                        )}`}
                      >
                        {employee.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-t text-gray-900">
                      {employee.address}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="flex justify-start py-3">
          <div>
            Showing{" "}
            {filteredEmployeeData.length > 0
              ? (currentPage - 1) * rowsPerPage + 1
              : 0}{" "}
            to{" "}
            {currentPage * rowsPerPage > filteredEmployeeData.length
              ? filteredEmployeeData.length
              : currentPage * rowsPerPage}{" "}
            of {filteredEmployeeData.length} records
          </div>
          <div className="flex space-x-2">
            {/* Previous Button */}
            {startPage > 1 && (
              <>
                <button
                  onClick={() => handlePageChange(1)}
                  className="px-3 py-1 border rounded-md"
                >
                  1
                </button>
                {startPage > 2 && <span className="px-3 py-1">...</span>}
              </>
            )}

            {/* Page Numbers */}
            {Array.from(
              { length: endPage - startPage + 1 },
              (_, i) => i + startPage
            ).map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={`px-3 py-1 border rounded-md ${currentPage === page ? "bg-gray-300" : "bg-white"
                  }`}
              >
                {page}
              </button>
            ))}

            {/* Next Button */}
            {endPage < totalPages && (
              <>
                {endPage < totalPages - 1 && (
                  <span className="px-3 py-1">...</span>
                )}
                <button
                  onClick={() => handlePageChange(totalPages)}
                  className="px-3 py-1 border rounded-md"
                >
                  {totalPages}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckinCheckoutReportTable;
